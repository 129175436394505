/* eslint-disable prettier/prettier */
export const environment = {
  production: false,
  stage: 'homologation',
  cypher_secret: '702c803d4654c95fad85035aa82b4648',
  apiPortfolio:
    'https://stg.gestaoconteudos.portfolio.inteligenciadevida.com.br/api',
  apiLiv: 'https://stg.api.inteligenciadevida.com.br/api',
  portalLiv: 'https://stg.portal.inteligenciadevida.com.br'
};

import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store
} from '@ngxs/store';
import {
  SetBookIdAction,
  SetColorsAction,
  SetContentAction,
  SetExternalStrapiIdAction,
  SetMascotAction,
  SetPortfolioAction
} from './book.actions';
import {
  BookDataModel,
  GradeModel,
  PortfolioContentPayload
} from './book.model';
import { ColorsModel } from 'src/app/shell/models/colors.model';
import { TeacherBookModel } from 'src/app/shell/models/teacher-book.model';
import { Model } from '../../models/liv-portfolio-response-protocol.model';
import { MascotModel } from 'src/app/shell/models/mascot.model';

export interface BookStateModel {
  bookId: number;
  externalStrapiId: number;
  colors: ColorsModel;
  mascot: MascotModel;
  content: Model<TeacherBookModel>;
  portfolio: PortfolioContentPayload;
}

const BOOK_STATE_TOKEN = new StateToken<BookStateModel>('book');

@State<BookStateModel>({
  name: BOOK_STATE_TOKEN,
  defaults: {
    bookId: null,
    externalStrapiId: null,
    colors: null,
    mascot: null,
    content: null,
    portfolio: null
  }
})
@Injectable()
export class BookState {
  constructor(private store: Store) {}

  @Selector() static bookId({ bookId }: BookStateModel): number {
    return bookId;
  }

  @Selector() static externalStrapiId({
    externalStrapiId
  }: BookStateModel): number {
    return externalStrapiId;
  }

  @Selector() static colors({ colors }: BookDataModel): ColorsModel {
    return colors;
  }

  @Selector() static content({
    content
  }: BookDataModel): Model<TeacherBookModel> {
    return content;
  }

  @Selector() static portfolio({
    portfolio
  }: BookStateModel): PortfolioContentPayload {
    return portfolio;
  }

  @Selector() static grade({ content }: BookDataModel): GradeModel {
    return content.attributes
      ? {
          serie: content.attributes.serie,
          name: content.attributes.turma
        }
      : null;
  }

  @Selector() static mascot({ mascot }: BookDataModel): MascotModel {
    return mascot;
  }

  @Action(SetBookIdAction) setBookId(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetBookIdAction
  ): void {
    patchState({
      bookId: payload
    });
  }

  @Action(SetPortfolioAction) setPortfolio(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetPortfolioAction
  ): void {
    patchState({
      portfolio: payload
    });
  }

  @Action(SetExternalStrapiIdAction) setExternalStrapiId(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetExternalStrapiIdAction
  ): void {
    patchState({
      externalStrapiId: payload
    });
  }

  @Action(SetColorsAction) setColors(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetColorsAction
  ): void {
    patchState({ colors: payload });
  }

  @Action(SetMascotAction) setMascot(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetMascotAction
  ): void {
    patchState({ mascot: payload });
  }

  @Action(SetContentAction) setContent(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetContentAction
  ): void {
    this.store.dispatch(new SetBookIdAction(payload.id));
    patchState({ content: payload });
  }
}

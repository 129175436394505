import { ColorsModel } from 'src/app/shell/models/colors.model';
import { TeacherBookModel } from 'src/app/shell/models/teacher-book.model';
import { Model } from '../../models/liv-portfolio-response-protocol.model';
import { MascotModel } from 'src/app/shell/models/mascot.model';
import { PortfolioContentPayload } from './book.model';

export class SetBookIdAction {
  static readonly type = '[Book] Set Book Id';
  constructor(public payload: number) {}
}

export class SetColorsAction {
  static readonly type = '[Book] Set Colors';
  constructor(public payload: ColorsModel) {}
}

export class SetContentAction {
  static readonly type = '[Book] Set Content';
  constructor(public payload: Model<TeacherBookModel>) {}
}

export class SetMascotAction {
  static readonly type = '[Book] Set Mascot';
  constructor(public payload: MascotModel) {}
}

export class SetExternalStrapiIdAction {
  static readonly type = '[Book] Set External Strapi Id';
  constructor(public payload: number) {}
}

export class SetPortfolioAction {
  static readonly type = '[Book] Set Portfolio content';
  constructor(public payload: PortfolioContentPayload) {}
}
